import React, { Component } from 'react';
import Navbar from '@components/navbar/navbar';
import './landingPageCommon.scss';
import CommonFunctions from '@functions/commonFunctions';
import rm from '@rm';

function LangingPage(props) {

    return (
        <div className="landingPageCommon background-blue">
            <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <div>
                <div className="landingPageCommon-landing-container">
                    <div className="landingPageCommon-inside">
                        <div className="landingPageCommon-content">
                            <h1 className="common-h1">
                            We offer a versatile range of investment solutions for the dynamic world of crypto.</h1>
                            {props.image ? <div>
                                <img src={props.imageRight} alt="Rewards Screen" className="screen phone screen-animate-landingPageCommon-screenRight " />
                                <img src={props.imageLeft} alt="Rewards Screen" className="screen phone screen-animate-landingPageCommon-screenLeft " />
                                <img src={props.image} alt="Rewards Screen" className="screen phone screen-animate-landingPageCommon screen_animate_landingPageCommon" />
                            </div> : <div />}
                            {/* <p className="common-p">
                                {props.text.description}
                            </p>
                            {props.text.button != undefined ?
                                <a href="https://play.google.com/store/apps/details?id=com.cisapp.co"> <button >{props.text.button}</button></a> : <div></div>} */}
                            <a href={rm.instance.urls.gettingStarted}><button className='goCornarButton'>GoCornar</button> </a>
                        </div>
                        <div className="landingPageCommon-image">
                            {props.image ?
                                <div>
                                    <img src={props.imageRight} alt="Rewards Screen" className="screen desktop screen-animate-landingPageCommon-screenRight " />
                                    <img src={props.imageLeft} alt="Rewards Screen" className="screen desktop screen-animate-landingPageCommon-screenLeft " />
                                    <img src={props.image} alt="Rewards Screen" className="screen desktop screen-animate-landingPageCommon screen_animate_landingPageCommon" />
                                </div> : props.children}
                        </div>
                    </div>
                </div>
                {props.children != undefined ? childrens(props) : <div></div>}
            </div>
        </div>
    );
}

export default LangingPage;

function childrens(props) {
    let container = <div></div>;
    switch (props.type) {
        case 'aboutUs':
            container = aboutUs(props);
            break;
    }
    return container;
}

function aboutUs(props) {
    return <div className="aboutUs-description"><p className="aboutUs-text common-p">{CommonFunctions.textToHTML(props.children)}</p></div>;
}