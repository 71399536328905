import React, { Component } from "react";
import "./blueComponent.scss";
import CommonFunctions from "@functions/commonFunctions";
import Heading from "@components/headings/heading";
import rm from "@rm";

class BlueComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = this.props.text;
    this.imageClassName = "screen desktop " + this.props.imageClassName;
    this.phoneImageClassName = "screen phone " + this.props.imageClassName;
    this.randomClassName = CommonFunctions.randomString(10);
    this.changeClassName = this.changeClassName.bind(this);
  }

  componentDidMount() {
    this.component = document.getElementById(this.randomClassName);
    window.addEventListener("scroll", this.changeClassName);
  }

  changeClassName() {
    if (
      window.pageYOffset >
      this.component.offsetTop - (window.innerHeight * 3) / 4
    ) {
      var screen = document.getElementById(this.randomClassName + "12");
      screen.className = this.imageClassName + " screen-animate-blueComponent";
      var screen = document.getElementById(
        this.randomClassName + " phoneBlueComponent"
      );
      screen.className =
        this.phoneImageClassName + " screen-animate-blueComponent";
      window.removeEventListener("scroll", this.changeClassName);
    }
  }

  render() {
    return (
      <div className="blueComponenet background-blue" id={this.randomClassName}>
        <canvas className="canvas"></canvas>
        <div className="blueComponenet-inside">
          <div className="blueComponenet-text">
            <h1 className="common-h1">
              {CommonFunctions.textToHTML(this.text.heading)}
            </h1>

            <img
              src={this.props.image}
              alt={this.text.altImage}
              className={this.phoneImageClassName + " hide"}
              id={this.randomClassName + " phoneBlueComponent"}
            />

            {this.text.description != undefined ? (
              <p className="common-p">
                {CommonFunctions.textToHTML(this.props.text.description)}
              </p>
            ) : (
              <Heading
                className="text-theme"
                type="Point"
                text={this.text.points}
                color="#8B6CD8"
              ></Heading>
            )}

            {this.props.gettingStartedButton ? (
              <a href={rm.instance.urls.gettingStarted}>
                <button>Get Started</button>{" "}
              </a>
            ) : (
              <div></div>
            )}
          </div>
          <img
            src={this.props.image}
            alt={this.props.text.altImage}
            className={this.imageClassName + " hide"}
            id={this.randomClassName + "12"}
          />
        </div>
      </div>
    );
  }
}

export default BlueComponent;
