import React, { Component } from 'react';
import './accounts.scss';
import DrawerImg from '@assets/drawerImg.png';
import Public from '../images/public.png';
import Work from '../images/work.png';
import Family from '../images/family.png';
import Friends from '../images/friends.png';


class Accounts extends Component {
    constructor(props) {
        super(props);
        this.state = { currentIndex :0  };
        this.text = this.props.text
        this.discription = this.props.text.accountSpaces;
        this.colors = ["#D878FF","#8B6CD8","#FFA621","#1F89CA"];  
        this.changeClassName = this.changeClassName.bind(this);   
        this.accountId = "accounts-animate";

    }

    componentDidMount() {
        this.component = document.getElementById(this.accountId);
        window.addEventListener("scroll", this.changeClassName);
    }

    changeClassName() {
        if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
            var screen = document.getElementById(this.accountId + " 5");
            screen.className = screen.className.replace("hide","") + " screen-animate-accounts";
            var screen = document.getElementById(this.accountId + " 6");
            screen.className = screen.className.replace("hide","") + " screen-animate-accounts";
            window.removeEventListener("scroll", this.changeClassName);
        }
    }
    onHower(index) {
        if(index != this.state.currentIndex) {
            this.setState({ currentIndex : index});
        }
    }

    render() { 
        return ( 
            <div className="accounts" id = {this.accountId}>
                <div className="accounts-inside">
                    <img src={DrawerImg} alt="Drawer Screen" className="screen desktop hide "  id ={this.accountId + " 5"}/>
                    <div className="accounts-content">
                        <h1 className="heading-theme">
                        {this.text.heading}
                        </h1>                        
                        <img src={DrawerImg} alt="Drawer Screen" className="screen phone hide" id ={this.accountId + " 6"}/>
                        <div className="accounts-icons">
                            <div><div className="accounts-public" onMouseOver={() => this.onHower(0)}><img src={Public} alt="Public Icon"/> </div><p className="text-theme">{this.text.accountSpaces[0].name} </p></div>
                            <div><div className="accounts-family" onMouseOver={() => this.onHower(1)}><img src={Family} alt="Family Icon"/> </div><p className="text-theme">{this.text.accountSpaces[1].name} </p></div>
                            <div><div className="accounts-friends" onMouseOver={() => this.onHower(2)}><img src={Friends} alt="Friends Icon"/></div><p className="text-theme">{this.text.accountSpaces[2].name}</p></div>
                            <div><div className="accounts-work"  onMouseOver={() => this.onHower(3)}><img src={Work} alt="Work Icon"/></div><p className="text-theme">{this.text.accountSpaces[3].name} </p></div>
                        </div>
                        <p className="accounts-discription" style={{color:this.colors[this.state.currentIndex]}}>
                        {this.props.text.accountSpaces[this.state.currentIndex].description}
                        </p>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Accounts;