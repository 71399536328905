import React, { Component } from 'react';
import Accounts from './accounts/accounts';
import Messaging from './messaging/messaging';
import UserBenefits from './userBenefits/userBenefits';
import Ecosystem from './ecosystem/ecosystem';
import Protection from './protection/protection';
import config from '@config/config.json';
import WhiteComponent from '@components/singleImageWhiteComponent/whiteComponent';
import CornersImg from '@assets/cornersImg.png';
import LandingPage from '@components/landingPageCommon/landingPageCommon';
import userProfileScreen from '@assets/userProfileScreen.png';
import DrawerImg from '@assets/drawerImg.png';



 
class ForUsers extends Component  {
    constructor(props) {
        super(props);
        this.state = {  };
        this.text = config.forUsers;
    }
    render() { 
        return ( 
            <div className="forUsers">
                <LandingPage  text={this.text.segment_1} image={DrawerImg} imageLeft={userProfileScreen} imageRight={CornersImg} ></LandingPage>
                <div className="background-blue">
                    <canvas className="canvas"></canvas>
                    <Accounts text={this.text.segment_2}></Accounts>
                </div>
                <Messaging text={this.text.segment_3}></Messaging>
                <div className="background-blue">
                    <canvas className="canvas"></canvas>
                <WhiteComponent text={this.text.segment_4} image={CornersImg}></WhiteComponent>
                </div>
                <UserBenefits text={this.text.segment_5}></UserBenefits>
                {/* <Ecosystem text={this.text.segment_6}></Ecosystem>
                <Protection text={this.text.segment_7}></Protection> */}
            </div>
         );
    }
}
 
export default ForUsers;