import React, { Component } from "react";
import "./features.scss";
import HomeFeedImg from "@assets/homeFeedImg.png";
import RewardsImg from "@assets/rewardsImg.png";
import DrawerImg from "@assets/drawerImg.png";
import rm from "@rm";
import { Typography, styled, Box, keyframes } from "@mui/material";

const screenAnimateFeatures1 = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-150%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
`;

const HeadingBox = styled(Box)(({ theme }) => ({
  width: "80%",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: "0em",
  textAlign: "center",
  marginTop: "50px",
  color: "black",
}));
const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "40px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "black",
  // width:'100rem'
}));

const BlobImage = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  top: "42%",
  height: "375px",
  width: "auto",
  // backgroundColor:'yellow',
  animation: `${screenAnimateFeatures1} 1s ease-in-out`,
  [theme.breakpoints.up("lg")]: {
    top: "50%",
    height: "500px",
  },
}));

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.featuresAnimation = "features-animate";
    this.changeClassName = this.changeClassName.bind(this);
  }

  componentDidMount() {
    this.component = document.getElementById(this.featuresAnimation);
    window.addEventListener("scroll", this.changeClassName);
  }
  changeClassName() {
    if (
      window.pageYOffset >
      this.component.offsetTop - (window.innerHeight * 3) / 4
    ) {
      var screen = document.getElementById(
        this.featuresAnimation + " featuresImg1"
      );
      screen.className =
        screen.className.replace("hide", "") + " screen-animate-features";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresImg2"
      );
      screen.className =
        screen.className.replace("hide", "") + " screen-animate-features";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresImg3"
      );
      screen.className =
        screen.className.replace("hide", "") + " screen-animate-features";

      var screen = document.getElementById(
        this.featuresAnimation + " featuresHeading1"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresHeading2"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresHeading3"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";

      var screen = document.getElementById(
        this.featuresAnimation + " featuresDescription1"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresDescription2"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";
      var screen = document.getElementById(
        this.featuresAnimation + " featuresDescription3"
      );
      screen.className = screen.className.replace("hide", "") + " text-anime";

      window.removeEventListener("scroll", this.changeClassName);
    }
  }
  render() {
    return (
      <div className="features" id={this.featuresAnimation}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HeadingBox>
            <Heading>Services That Cater to Your Every Need</Heading>
            {/* <SubHeading>Services That Cater to Your Every Need</SubHeading> */}
          </HeadingBox>
          <div className="features-inside">
            <div className="feature-one feature">
              <div style={{ position: "relative" }}>
                <img
                  src={DrawerImg}
                  alt="Feature One Screen"
                  className="screen desktop phone hide"
                  id={this.featuresAnimation + " featuresImg1"}
                  style={{ zIndex: 1, position: "relative" }}
                />
                <BlobImage id={this.featuresAnimation + " featuresImg1"}>
                  <svg
                    viewBox="0 0 500 500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    id="blobSvg"
                    className="blobSvg"
                  >
                    <path id="blob" fill="#F8281C4D">
                      <animate
                        attributeName="d"
                        dur="10000ms"
                        repeatCount="indefinite"
                        values="M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;

                                                M477,301.5Q464,353,419,379Q374,405,336,433Q298,461,254,442.5Q210,424,163,421Q116,418,95,376.5Q74,335,64,292.5Q54,250,51.5,201.5Q49,153,93,130.5Q137,108,174,92.5Q211,77,252,69.5Q293,62,349,58.5Q405,55,396.5,119Q388,183,439,216.5Q490,250,477,301.5Z;

                                                M468,301Q462,352,424,386Q386,420,344,448Q302,476,254,458.5Q206,441,180.5,405Q155,369,103.5,357Q52,345,35.5,297.5Q19,250,50,209.5Q81,169,114.5,145.5Q148,122,181,108Q214,94,255,70Q296,46,338,66.5Q380,87,407.5,124Q435,161,454.5,205.5Q474,250,468,301Z;

                                                M470.5,303Q470,356,411,367Q352,378,325.5,421.5Q299,465,253,452.5Q207,440,162.5,428Q118,416,116,365.5Q114,315,91,282.5Q68,250,52.5,198.5Q37,147,87,127.5Q137,108,176,102.5Q215,97,258.5,59.5Q302,22,352,40.5Q402,59,436,101.5Q470,144,470.5,197Q471,250,470.5,303Z;

                                                M444.5,290.5Q418,331,384,353.5Q350,376,322,408.5Q294,441,253,428.5Q212,416,170,409.5Q128,403,107.5,365.5Q87,328,50,289Q13,250,25,198.5Q37,147,79,117.5Q121,88,166.5,86.5Q212,85,253,71.5Q294,58,333,77.5Q372,97,408.5,126.5Q445,156,458,203Q471,250,444.5,290.5Z;

                                                M449,300Q458,350,432,397.5Q406,445,349.5,441.5Q293,438,245.5,458Q198,478,161.5,442.5Q125,407,89,376Q53,345,59.5,297.5Q66,250,50,198Q34,146,78.5,118.5Q123,91,165.5,78.5Q208,66,249.5,68.5Q291,71,335,79.5Q379,88,400.5,127.5Q422,167,431,208.5Q440,250,449,300Z;

                                                M437.5,287.5Q407,325,384,357Q361,389,333.5,441.5Q306,494,261.5,445Q217,396,166,407.5Q115,419,95,376.5Q75,334,74.5,292Q74,250,50,196Q26,142,86.5,131.5Q147,121,174.5,80.5Q202,40,249.5,42.5Q297,45,333,73Q369,101,378,142.5Q387,184,427.5,217Q468,250,437.5,287.5Z;

                                                M475.5,298.5Q452,347,402,362Q352,377,319,392Q286,407,249,411.5Q212,416,181,396Q150,376,111.5,355.5Q73,335,68.5,292.5Q64,250,49,198Q34,146,78.5,118.5Q123,91,168,88.5Q213,86,257,55.5Q301,25,332,66.5Q363,108,378,144.5Q393,181,446,215.5Q499,250,475.5,298.5Z;

                                                M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;
                                                "
                      ></animate>
                    </path>
                  </svg>
                </BlobImage>
              </div>
              <h3
                className="feature-one hide"
                id={this.featuresAnimation + " featuresHeading1"}
              >
                {this.props.text.page_1.heading}
              </h3>
              <p
                className="common-p hide"
                id={this.featuresAnimation + " featuresDescription1"}
              >
                {this.props.text.page_1.discription}
              </p>

              {/* <a href={rm.instance.urls.forUsers}> <button >{this.props.text.button}</button></a> */}
            </div>
            <div className="feature-two feature">
              <div style={{ position: "relative" }}>
                <img
                  src={RewardsImg}
                  alt="Feature Two Screen"
                  className="screen desktop phone hide"
                  id={this.featuresAnimation + " featuresImg2"}
                />
                <BlobImage id={this.featuresAnimation + " featuresImg1"}>
                  <svg
                    viewBox="0 0 500 500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    id="blobSvg"
                  >
                    <path id="blob" fill="#FF84004D">
                      <animate
                        attributeName="d"
                        dur="10000ms"
                        repeatCount="indefinite"
                        values="M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;

                                                M477,301.5Q464,353,419,379Q374,405,336,433Q298,461,254,442.5Q210,424,163,421Q116,418,95,376.5Q74,335,64,292.5Q54,250,51.5,201.5Q49,153,93,130.5Q137,108,174,92.5Q211,77,252,69.5Q293,62,349,58.5Q405,55,396.5,119Q388,183,439,216.5Q490,250,477,301.5Z;

                                                M468,301Q462,352,424,386Q386,420,344,448Q302,476,254,458.5Q206,441,180.5,405Q155,369,103.5,357Q52,345,35.5,297.5Q19,250,50,209.5Q81,169,114.5,145.5Q148,122,181,108Q214,94,255,70Q296,46,338,66.5Q380,87,407.5,124Q435,161,454.5,205.5Q474,250,468,301Z;

                                                M470.5,303Q470,356,411,367Q352,378,325.5,421.5Q299,465,253,452.5Q207,440,162.5,428Q118,416,116,365.5Q114,315,91,282.5Q68,250,52.5,198.5Q37,147,87,127.5Q137,108,176,102.5Q215,97,258.5,59.5Q302,22,352,40.5Q402,59,436,101.5Q470,144,470.5,197Q471,250,470.5,303Z;

                                                M444.5,290.5Q418,331,384,353.5Q350,376,322,408.5Q294,441,253,428.5Q212,416,170,409.5Q128,403,107.5,365.5Q87,328,50,289Q13,250,25,198.5Q37,147,79,117.5Q121,88,166.5,86.5Q212,85,253,71.5Q294,58,333,77.5Q372,97,408.5,126.5Q445,156,458,203Q471,250,444.5,290.5Z;

                                                M449,300Q458,350,432,397.5Q406,445,349.5,441.5Q293,438,245.5,458Q198,478,161.5,442.5Q125,407,89,376Q53,345,59.5,297.5Q66,250,50,198Q34,146,78.5,118.5Q123,91,165.5,78.5Q208,66,249.5,68.5Q291,71,335,79.5Q379,88,400.5,127.5Q422,167,431,208.5Q440,250,449,300Z;

                                                M437.5,287.5Q407,325,384,357Q361,389,333.5,441.5Q306,494,261.5,445Q217,396,166,407.5Q115,419,95,376.5Q75,334,74.5,292Q74,250,50,196Q26,142,86.5,131.5Q147,121,174.5,80.5Q202,40,249.5,42.5Q297,45,333,73Q369,101,378,142.5Q387,184,427.5,217Q468,250,437.5,287.5Z;

                                                M475.5,298.5Q452,347,402,362Q352,377,319,392Q286,407,249,411.5Q212,416,181,396Q150,376,111.5,355.5Q73,335,68.5,292.5Q64,250,49,198Q34,146,78.5,118.5Q123,91,168,88.5Q213,86,257,55.5Q301,25,332,66.5Q363,108,378,144.5Q393,181,446,215.5Q499,250,475.5,298.5Z;

                                                M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;
                                                "
                      ></animate>
                    </path>
                  </svg>
                </BlobImage>
              </div>
              <h3
                className="feature-two hide"
                id={this.featuresAnimation + " featuresHeading2"}
              >
                {this.props.text.page_2.heading}
              </h3>
              <p
                className="common-p hide"
                id={this.featuresAnimation + " featuresDescription2"}
              >
                {this.props.text.page_2.discription}
              </p>
              {/* <a href={rm.instance.urls.forCreators}> <button >{this.props.text.button}</button></a> */}
            </div>
            <div className="feature-three feature">
              <div style={{ position: "relative" }}>
                <img
                  src={HomeFeedImg}
                  alt="Feature Three Screen"
                  className="screen desktop phone hide"
                  id={this.featuresAnimation + " featuresImg3"}
                />
                <BlobImage id={this.featuresAnimation + " featuresImg1"}>
                  <svg
                    viewBox="0 0 500 500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    id="blobSvg"
                  >
                    <path id="blob" fill="#5CA4014D">
                      <animate
                        attributeName="d"
                        dur="10000ms"
                        repeatCount="indefinite"
                        values="M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;

                                                M477,301.5Q464,353,419,379Q374,405,336,433Q298,461,254,442.5Q210,424,163,421Q116,418,95,376.5Q74,335,64,292.5Q54,250,51.5,201.5Q49,153,93,130.5Q137,108,174,92.5Q211,77,252,69.5Q293,62,349,58.5Q405,55,396.5,119Q388,183,439,216.5Q490,250,477,301.5Z;

                                                M468,301Q462,352,424,386Q386,420,344,448Q302,476,254,458.5Q206,441,180.5,405Q155,369,103.5,357Q52,345,35.5,297.5Q19,250,50,209.5Q81,169,114.5,145.5Q148,122,181,108Q214,94,255,70Q296,46,338,66.5Q380,87,407.5,124Q435,161,454.5,205.5Q474,250,468,301Z;

                                                M470.5,303Q470,356,411,367Q352,378,325.5,421.5Q299,465,253,452.5Q207,440,162.5,428Q118,416,116,365.5Q114,315,91,282.5Q68,250,52.5,198.5Q37,147,87,127.5Q137,108,176,102.5Q215,97,258.5,59.5Q302,22,352,40.5Q402,59,436,101.5Q470,144,470.5,197Q471,250,470.5,303Z;

                                                M444.5,290.5Q418,331,384,353.5Q350,376,322,408.5Q294,441,253,428.5Q212,416,170,409.5Q128,403,107.5,365.5Q87,328,50,289Q13,250,25,198.5Q37,147,79,117.5Q121,88,166.5,86.5Q212,85,253,71.5Q294,58,333,77.5Q372,97,408.5,126.5Q445,156,458,203Q471,250,444.5,290.5Z;

                                                M449,300Q458,350,432,397.5Q406,445,349.5,441.5Q293,438,245.5,458Q198,478,161.5,442.5Q125,407,89,376Q53,345,59.5,297.5Q66,250,50,198Q34,146,78.5,118.5Q123,91,165.5,78.5Q208,66,249.5,68.5Q291,71,335,79.5Q379,88,400.5,127.5Q422,167,431,208.5Q440,250,449,300Z;

                                                M437.5,287.5Q407,325,384,357Q361,389,333.5,441.5Q306,494,261.5,445Q217,396,166,407.5Q115,419,95,376.5Q75,334,74.5,292Q74,250,50,196Q26,142,86.5,131.5Q147,121,174.5,80.5Q202,40,249.5,42.5Q297,45,333,73Q369,101,378,142.5Q387,184,427.5,217Q468,250,437.5,287.5Z;

                                                M475.5,298.5Q452,347,402,362Q352,377,319,392Q286,407,249,411.5Q212,416,181,396Q150,376,111.5,355.5Q73,335,68.5,292.5Q64,250,49,198Q34,146,78.5,118.5Q123,91,168,88.5Q213,86,257,55.5Q301,25,332,66.5Q363,108,378,144.5Q393,181,446,215.5Q499,250,475.5,298.5Z;

                                                M450,303Q470,356,437,399.5Q404,443,344.5,423Q285,403,247.5,413.5Q210,424,164,420Q118,416,83.5,381.5Q49,347,64.5,298.5Q80,250,52.5,196Q25,142,70.5,112Q116,82,165,86Q214,90,257,60.5Q300,31,346,51.5Q392,72,426.5,110.5Q461,149,445.5,199.5Q430,250,450,303Z;
                                                "
                      ></animate>
                    </path>
                  </svg>
                </BlobImage>
              </div>
              <h3
                className="feature-three hide"
                id={this.featuresAnimation + " featuresHeading3"}
              >
                {this.props.text.page_3.heading}
              </h3>
              <p
                className="common-p hide"
                id={this.featuresAnimation + " featuresDescription3"}
              >
                {this.props.text.page_3.discription}
              </p>
              {/* <a href={rm.instance.urls.forInvestors}> <button>{this.props.text.button}</button></a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
