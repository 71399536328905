import React from 'react';
import './MyblogStyle.css';
import DatabreachImgage from '../images/databreach.jpeg';
import Navbar from '@components/navbar/navbar';

function Databreach(props) {
    return (
        
        <div className="background-blue">
        <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <div className="blog">
                <div className="blog-image">
                    <img src={DatabreachImgage} alt='Blockchain'></img>
                </div>

                <div className="blog-inside">
                    <div className="blog-heading"><h2>DATA BREACHING BY SOCIAL MEDIA PLATFORMS</h2></div>
                    <br></br><br></br>
                    <div className="blog-content">
                        <h3>1.What is Data Breaching?</h3>
                        <br />
                        <p>“Data Breaching mean the intentional or unintentional release of secure or private/confidential information to an untrusted environment. Other terms for this phenomenon include unintentional information disclosure, data leak, information leakage and also, data spill. Incidents range from concerted attacks by black hats, or individuals who hack for some kind of personal gain, associated with organized crime, political activist or national governments to careless disposal of used computer equipment or data storage media and unhackable source.
                        <br></br>The different type of data breaches are leakage of financial information such as credit card or bank details, personal health information (PHI), Personally Identifiable Information (PII), trade secrets of corporations or intellectual property. Most data breaches involve overexposed and vulnerable unstructured data – files, documents, and sensitive information.”</p>
                        <br /><br />
                        <h3>2. Which Social Media Platforms are Involved and What is their Real Motive?</h3>
                        <br /><br />
                        <h3>(a)Biggest data breaches:</h3>
                        <h3>Facebook’s data breach:</h3>
                        <p>It has been reported several times that certain Facebook apps are involved in data breaching of those who are using them, to advertising and Internet tracking companies. And without the users having a clue!</p>
                        <br /><br />
                        <p>Here’s how the “leakage” works: during the app’s installation process, you are prompted to accept certain terms, and once you click “Allow”, the application receives an “access token”. Some of the Facebook apps are leaking these access tokens to advertisers, granting them access to personal-profile data such as chat logs and photos. However, no disclaimer is showed announcing you your data is being transferred to third parties. Thus, your online privacy and safety are put at risk.</p>
                        <br /><br />
                        <h3>Online social tracking:</h3>
                        <br />
                        <p>We all use the “Like”, “Tweet”, “+1”, and other buttons to share content with our friends. But these social widgets are also valuable tracking tools for social media websites. They work with cookies – small files stored on a computer that enable tracking the user across different sites – that social websites place in browsers when you create an account or log in, and together they allow the social websites to recognize you on any site that uses these widgets. Thus, your interests and online shopping behaviour can be easily tracked, and your internet privacy rudely invaded. And things get worse.</p>
                        <br /><br />
                        <p>A new study has found, raising the possibility that users of such sites can be tracked everywhere they go online. "The ability to link information across traversals on the Internet coupled with the wide range of daily actions performed by hundreds of millions of user on the Internet raises privacy issues, particularly to the extent users may not understand the consequences of having their PII [personally identifiable information] available to aggregators," the study states.</p>
                        <br /><br />
                        <p><h3 className='remove-break'>Vodafone, Data Protection breach example: </h3> A breach which has leaked personal data for two million Vodafone Germany customers has been claimed to be the work of an insider, according to Vodafone. The company warned the leak could lead to increased risk of targeted phishing attacks. “This attack could only be carried out with high criminal intent and insider knowledge and was launched deep inside the IT infrastructure of the company,” Vodafone said, according to a report by Phys.org. The information was stolen from a database within the company network, according to the BBC. Vodafone said that the attackers had not gained access to enough information to access clients’ bank accounts – but that the data could raise the risk of “phishing” attacks. Only German customers were affected by the attack, Vodafone said, and those affected would be contacted by post.</p>

                        <br /><br />
                        <p><h3 className='remove-break'>Data Breach 2020:</h3> A customer support database holding over 280 million Microsoft customer records was left unprotected on the web. Microsoft’s exposed database disclosed email addresses, IP addresses, and support case details. Microsoft says the database did not include any other personal information.</p>
                        <br /><br />
                        <p><h3 className='remove-break'>How do most data breaches occur?</h3> It involves tracking people’s activities online and harvesting personal data and conversations from social media, job websites and online forums. Most security breaches are caused by research companies (the harvesters), and sell the compiled data to other companies. These, in turn, use these details to design targeted ad campaigns for their products. While one might argue that people are knowingly sharing personal details on social media and thus, it’s free for everyone’s use, data harvesters don’t ask for the owner’s consent. And this raises an ethics and therefore is called breaching of data.</p>
                        <br /><br />
                        <h3>What to do after a Data Breach?</h3>
                        <br /><br />
                        <h3>There are two ways:</h3>
                        <br /><br />
                        <p>(a) Unfortunately, you cannot control the data breaching, but you can control how much information about yourself you put out there. So, edit your privacy settings right. Also, if you find yourself in the database of some “people-search” site you can ask them to remove your details, and then edit down the information made public via social networking.</p>
                        <br /><br />
                        <p>Facebook privacy issues should be a main concern when networking. Even if cookies are not necessarily harmful, just picture them as a quiet stalker following your every step. You can delete them from your web browser and limit what types of cookies may be placed on the computer.</p>
                        <br /><br />
                        <p>(b) Cornars: World’s First Social Platform with Private and Public Spaces. It solves the THREE PROBLEMS:</p>
                        <br /><br />
                        <p><h3 className='remove-break'>1. PRIVACY: </h3>It keeps its users’ information private and prevent from deep risk</p>

                        <p><h3 className='remove-break'>2. FAKE PEOPLE: </h3>No fake people and bots allowed</p>

                        <p><h3 className='remove-break'>3. MULTIPLE APPS:</h3>Just Cornars and no other social media.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Databreach;