import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import hyderabad from "./images/hyderabad.png";
import mumbai from "./images/mumbai.png";
import bengaluru from "./images/bengaluru.png";
import pune from "./images/pune.png";
import time from "./images/time.png";
import queue from "./images/queue.png";
import briefcase from "./images/briefcase.png";
import "./cities.scss";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: "0em",
  textAlign: "center",
  marginTop: "50px",
  // color: "white",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "26px",
  fontWeight: 600,
  //   lineHeight: "48px",
  textAlign: "center",
  marginTop: "50px",
  color: "white",
}));

const CityName = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 600,
  letterSpacing: "0em",
  color: "white",
  textAlign: "center",
  marginLeft: "15px",
}));

const CardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "50px",
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "10rem",
  alignItems: "center",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "10rem",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor:'#FFA621'
}));

const Circle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "50px",
  width: "100px",
  height: "100px",
  backgroundColor: "#FFA621",
  borderRadius: "50%",
}));

const Connector = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "5px",
  backgroundColor: "#FFA621",
  marginTop: "50px",
  [theme.breakpoints.up("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "225px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "black",
}));

const Tag = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: "600",
  lineHeight: "32px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "#FFA621",
  marginBottom: "20px",
}));

const CircleImage = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
}));

const citiesData = [
  { name: "Hyderabad", image: hyderabad },
  { name: "Bengaluru", image: bengaluru },
];

function Cities() {
  return (

    <MainWrapper>
      <Heading>The GoCornar Advantage</Heading>
      <Box display={"flex"} alignItems={"center"}>
        <Circle>
          <CircleImage alt="icon" src={queue} />
        </Circle>
        <Connector></Connector>
        <Circle>
          <CircleImage alt="icon" src={time} />
        </Circle>
        <Connector></Connector>
        <Circle>
          <CircleImage alt="icon" src={briefcase} />
        </Circle>
      </Box>
      <Box
        style={{
          marginLeft: "75px",
          marginRight: "75px",
          marginTop: "25px",
          marginBottom: "25px",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              flexDirection={"column"}
            >
              <Tag>Queue Elimination</Tag>
              <Text>
                Bid farewell to long lines and crowded spaces. GoCornar is
                designed to eliminate queues, ensuring a swift and hassle-free
                experience.
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              flexDirection={"column"}
            >
              <Tag>Time-Saving</Tag>
              <Text>
                We value your time. Our services are crafted to save you
                precious minutes, giving you more freedom to do the things you
                love.
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              flexDirection={"column"}
            >
              <Tag>Supporting Local Businesses</Tag>
              <Text>
                GoCornar isn't just about convenience; it's about community. By
                providing a digital platform for local street food vendors and
                grocery stores, we empower businesses to thrive in the digital
                age.
              </Text>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Wrapper>
        <Heading style={{color:'white'}} >Our Pilot launch in</Heading>
        <Grid container spacing={0}>
          {citiesData.map((city, index) => (
            <Grid item xs={6} md={6}>
              <CardBox>
                <Box>
                  <img src={city.image} width={"80px"} height={"80px"} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CityName>{city.name}</CityName>
                </Box>
              </CardBox>
            </Grid>
          ))}
        </Grid>
        <SubHeading>launching soon in other metro cities .</SubHeading>
      </Wrapper>
    </MainWrapper>
   
  );
}

export default Cities;
