import React from "react";

import Navbar from "@components/navbar/navbar";

function UserPolicy(props) {
  return (
    <div className="background-blue">
      <canvas className="canvas"></canvas>
      <Navbar></Navbar>
      <section>
        <div className="terms">
          <h2 className="common-h1">User Policy</h2>
          <p className="common-p">
            Hello, you’ve probably been directed to this page because we requested additional rights to share your photo across our Feed. We love to see how you use the content and functionalities in our Cornars in your daily life —from creating your own content, to personalizing your feed with our cornars feature to segregate content, to connecting with your family, friends, work colleagues and in public space—we love seeing you on Cornars in action!We regularly notify about the daily activity, latest news across our feed , trending feed, trending content.
            <br></br>
            <br></br>
            By responding to our user policy by accepting ityou are agreeing to the following:
            <br></br>
            <br></br>
            <br></br>
            <h4>User Content</h4>
            <br></br>
            As between you and us, you own all content and information you post or share using the Cornars(referred to as “User Content”), such as posting or sharing comments, photos, and videos. You give Cornars, permission to use your User Content as follows: you grant to Cornars, a license to reproduce, display, perform, distribute, and otherwise use your User Content in connection with the Cornars, marketing purposes, including without limitation in Cornars, catalogs, email and other usercommunications, store materials and other marketing. We may display advertisements inconnection with your
            User Content or on pages where your User Content may be viewed by you or others, and we may use your User Content to advertise and promote Cornars, or oninternet. Our license to your User Content is non-exclusive, meaning you may use the User Content for your own purposes or let others use your User Content for their purposes. Our license to your User Content is royalty free, meaning we do not owe you anything else in connection with our use of your User Content. We may exercise our rights anywhere in the world. Finally, our license is perpetual, meaning that our license lasts for
            an indefinite period of time.
            <br></br>
            <br></br>
            <br></br>
            <h4>You promise that:</h4>
            <ul style={{ margin: "1.5rem" }}>
              <li>You own all rights to your User Content or, alternatively, that you have the right to give Cornars, the rights described above</li>
              <li>You have paid and will pay in full any fees or other payments that may be related to the use of your User Content</li>
              <li>Your User Content does not infringe the intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party.</li>
            </ul>
            <br></br>
            We may refuse to accept or transmit User Content for any reason. We may remove User Content from the Cornarsfor any reason.
            <br></br>
            <br></br>
            <br></br>
            <h4>Copyright and Intellectual Property Policy</h4>
            <br></br>
            If you believe that a user of the Cornarshas infringed on your intellectual property rights, or if we have taken down your materials due to suspicion of copyright infringement, and you would like to dispute the alleged infringement, please feel free to notify us by emailing tech@cisapp.co
            <br></br>
            <br></br>
            Thank you!
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </p>
        </div>
      </section>
    </div>
  );
}

export default UserPolicy;
