import React, { useEffect } from 'react';
import a from '../images/1.png';
import b from '../images/2.png';
import c from '../images/3.png';
import d from '../images/4.png';
import e from '../images/5.png';
import f from '../images/6.png';
import g from '../images/7.png';
import h from '../images/8.png';
import i from '../images/9.png';
import j from '../images/10.png';
import k from '../images/11.png';
import l from '../images/12.png';
import { Box, Container, Grid, Typography, styled } from '@mui/material';
import './ClientSection.css'

const Heading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#FFB900'
}))

const SubHeading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: 600,
    // lineHeight: '64px',
    letterSpacing: '0em',
    textAlign: 'center',
}))

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0em',
    marginLeft: '5%',
    width: '80%',
    textAlign: 'center',
    color: '#7B7B7B'
}))

const Name = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 600,
    letterSpacing: '0em',
    textAlign: 'center',
    marginTop: '3%',
    marginLeft: '-10%',
    // color:'#7B7B7B'
}))

const NameTag = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#7B7B7B'
}))

const ClientSection = () => {
    return (
        <Container maxWidth="xl" style={{height: '500px'}} >
            <Box>
                {/* <Heading>SATISFIED CLIENTS</Heading> */}
                <SubHeading>Join Us in Revolutionizing Convenience</SubHeading>
                <br /><br /><br />
                <Grid container spacing={0}>
                    <Grid item xs={6} md={7}>
                        <div style={{
                            position: 'relative',
                            width: '100%', height: '400px',
                            marginLeft: '2%',
                            overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center'                            
                        }}
                        >
                            <ContentText>GoCornar isn't just an app; it's a lifestyle. Embrace the future of shopping and dining by downloading our app today. Experience the joy of efficient, time-saving services while supporting local businesses in your community</ContentText>
                            <Name>GoCornar - Where Convenience Meets Community.</Name>
                            {/* <NameTag>Master Chef</NameTag> */}
                        </div>
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <div className="random-image-container">
                            <div className="random-image position1"><img src={a} className='size1' /></div>
                            <div className="random-image position2"><img src={b} className='size2' /></div>
                            <div className="random-image position3"><img src={c} className='size3' /></div>
                            <div className="random-image position4"><img src={d} className='size2' /></div>
                            <div className="random-image position5"><img src={e} className='size5' /></div>
                            <div className="random-image position6"><img src={f} className='size2' /></div>
                            <div className="random-image position7"><img src={g} className='size2' /></div>
                            <div className="random-image position8"><img src={h} className='size1' /></div>
                            <div className="random-image position9"><img src={i} className='size1' /></div>
                            <div className="random-image position10"><img src={j} className='size4' /></div>
                            <div className="random-image position12"><img src={l} className='size12' /></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default ClientSection