import React, { Component } from "react";
import LandingPage from "./landingPage/landingPage";
import Features from "./features/features";
import WhiteComponent from "@components/singleImageWhiteComponent/whiteComponent";
import BlueComponent from "@components/singleImageBlueComponent/blueComponent";
import RewardsImg from "@assets/rewardsImg.png";
import DrawerImg from "@assets/drawerImg.png";
import config from "@config/config.json";
import CommonFunctions from "@functions/commonFunctions";
import "./home.scss";
import Logo from "@components/splash/Logo";
import Footer from "@components/footer/footer";
import PrivateChatHome from "./images/privateChatHome.png";
import ScrollToTopButton from "../../components/scrollToTop/ScrollToTop";
import Cities from "./cities/cities";
import BottomSection from "../oldScreens/AboutUs/TopComponents/BottomSection";
import TopSection from "../oldScreens/AboutUs/TopComponents/TopSection";
import scan from "./images/scan.png";
import pick from "./images/pick.png";
import eat from "./images/eat.png";
import ClientSection from "../oldScreens/AboutUs/BottomComponents/ClientSection";

class Home extends Component {
  constructor(props) {
    super(props);
    this.text = config.home;
    this.goCornarsText = config.goCornars;
    if (CommonFunctions.getSessionStorage("logo") === "1") {
      this.state = {
        logo: true,
      };
    } else {
      this.state = {
        logo: false,
      };
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState((currentState) => {
        return {
          logo: false,
        };
      });
      CommonFunctions.setSessionStorage("logo", "0");
    }, 4000);
  }

  render() {
    return (
      <div
        className="home"
        style={
          this.state.logo
            ? { overflowY: "hidden", position: "relative", height: "100vh" }
            : { position: "relative" }
        }
      >
        {/* {this.state.logo ? <div className="home-intro">{<Logo></Logo>}</div> : ""} */}

        <LandingPage
          title={CommonFunctions.textToHTML(this.text.segment_1.heading)}
          description={CommonFunctions.textToHTML(
            this.text.segment_1.description
          )}
          button={CommonFunctions.textToHTML(this.text.segment_1.button)}
        ></LandingPage>

        <div className="background-blue">
          <canvas className="canvas"></canvas>


          <Features text={this.text.segment_5}></Features>

          <BottomSection
            bgColor={"#5CA40166"}
            image={scan}
            heading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_1.heading
            )}
            boldHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_1.boldHeading
            )}
            restHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_1.restHeading
            )}
            subHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_1.subHeading
            )}
            description={CommonFunctions.textToHTML(
              this.goCornarsText.segment_1.description
            )}
          ></BottomSection>

          <TopSection
            bgColor={"#FF840066"}
            image={pick}
            heading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_2.heading
            )}
            boldHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_2.boldHeading
            )}
            restHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_2.restHeading
            )}
            subHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_2.subHeading
            )}
            description={CommonFunctions.textToHTML(
              this.goCornarsText.segment_2.description
            )}
          ></TopSection>

          <BottomSection
            bgColor={"#F8281C66"}
            image={eat}
            heading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_3.heading
            )}
            boldHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_3.boldHeading
            )}
            restHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_3.restHeading
            )}
            subHeading={CommonFunctions.textToHTML(
              this.goCornarsText.segment_3.subHeading
            )}
            description={CommonFunctions.textToHTML(
              this.goCornarsText.segment_3.description
            )}
          ></BottomSection>
          <Cities></Cities>
          <ClientSection></ClientSection>
        </div>
        <Footer></Footer>
        <ScrollToTopButton></ScrollToTopButton>
      </div>
    );
  }
}

export default Home;
