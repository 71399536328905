import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Button,
} from "@mui/material";
import React from "react";

const MainWarraper = styled(Box)(({ theme }) => ({
  height: "400px",
  backgroundColor: "#FFB90066",
  padding: "24px 48px",
  borderRadius: "80px",
  display: "flex",
  alignItems: "center",
}));

const MessageBox = styled(Box)(({ theme }) => ({
  width: "65%",
  borderRadius: "80px",
  order: 1,
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "35%",
  borderRadius: "80px",
  order: 1,
  height: "100%",
  position: "relative",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "30px",
  fontWeight: "500",
  lineHeight: "36px",
  letterSpacing: "0em",
  textAlign: "left",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left",
}));

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left",
}));

const Pic = styled("img")(({ theme }) => ({
  width: "300px",
  height: "auto",
  position: "absolute",
  top: "-30%",
  left: "10%",
}));

const BottomSection = ({
  bgColor,
  image,
  heading,
  boldHeading,
  restHeading,
  subHeading,
  description,
}) => {
  return (
    <Container maxWidth="lg" style={{height: "700px", display:'flex', justifyContent:'center', alignItems:'center'}}>
      <MainWarraper style={{ backgroundColor: bgColor }}>
        <ImageBox>
          <Pic src={image} />
        </ImageBox>
        <MessageBox>
          <Heading>
            {heading}
            <b>{boldHeading}</b>
            {restHeading}
          </Heading>
          <br />
          <br />
          <br />
          <SubHeading>{subHeading}</SubHeading>
          <br />
          <br />
          <Description>{description}</Description>
        </MessageBox>
      </MainWarraper>
    </Container>
  );
};

export default BottomSection;
