import { Box, Container, Grid, Paper, Typography, styled } from '@mui/material'
import React from 'react'
import manImage from '../images/9.png'


const Item = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    height: '300px',
    width: '100%',
    display: 'flex'
}));

const Circle = styled(Box)(({ theme }) => ({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#F29F05',
    marginBottom: '30px'
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#FFB900'
}))

const Description = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#787878'
}))

const PaperItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    width: '225px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    borderRadius: '10px'
}));

const PaperHeading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
}));

const PaperText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    // lineHeight: '24px',
    // letterSpacing: '0em',
    textAlign: 'left',
    color: '#787878'
}));

const Name = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'right',
    position: 'absolute',
    top: '55%',
    left: '40%'
}));

const PicBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '75%',
    height: '80px',
    width: '100%',
    left: '10%',
    display: 'flex'
}));

const Pic = styled('img')(({ theme }) => ({
    // position: 'absolute',
    top: '75%',
    height: '80px',
    width: '80px',
    left: '10%'
}));


const ReviewSection = () => {
    return (
        <Container maxWidth="lg" style={{ marginTop: '50px', marginBottom: '50px' }}>
            <Box sx={{ height: '300px', padding: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Box display={'flex'} flexDirection={'column'} >
                            <Heading>HAPPY CUSTOMERS</Heading>
                            <PaperHeading>Enim lobortis scelerisque fermentum dui faucibus Tempor commodo ullamcorper a lacus vestibulumsedarcu non.Enim lobortis</PaperHeading>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Box position={'relative'} height={'100%'} width={'100%'}>
                            <PaperItem>
                                <PaperText>
                                    Enim lobortis scelerisque fermentum dui faucibus Tempor commodo ullamcorper a lacus vestibulumsedarcu non.
                                </PaperText>
                            </PaperItem>
                            <PicBox>
                                <Pic alt='' src={manImage} />
                                <Name>Enim lobortis</Name>
                            </PicBox>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Box position={'relative'} height={'100%'} width={'100%'}>
                            <PaperItem>
                                <PaperText>
                                    Enim lobortis scelerisque fermentum dui faucibus Tempor commodo ullamcorper a lacus vestibulumsedarcu non.
                                </PaperText>
                            </PaperItem>
                            <PicBox>
                                <Pic alt='' src={manImage} />
                                <Name>Enim lobortis</Name>
                            </PicBox>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Box position={'relative'} height={'100%'} width={'100%'}>
                            <PaperItem>
                                <PaperText>
                                    Enim lobortis scelerisque fermentum dui faucibus Tempor commodo ullamcorper a lacus vestibulumsedarcu non.
                                </PaperText>
                            </PaperItem>
                            <PicBox>
                                <Pic alt='' src={manImage} />
                                <Name>Enim lobortis</Name>
                            </PicBox>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default ReviewSection