import React, { Component } from "react";
import "./footer.scss";
import Spinner from "@assets/Spinner.gif";
import cornarFooterLogo from "@assets/cornarFooterLogo.png";
import Facebook from "@assets/facebook.png";
import Instagram from "@assets/instagram.png";
import LinkidIn from "@assets/linkidIn.png";
import Twitter from "@assets/twitter.png";
import Send from "@assets/send.png";
import CopyRights from "@assets/copyRights.png";
import CopyRightsDark from "@assets/copyRightsDark.png";
import CommonFunctions from "@functions/commonFunctions";
import Apiresponse from "@functions/api";
import GooglePlayStore from "@assets/playStore.png";
import AppStoreBadge from "@assets/appStore.png";
import rm from "@rm";
import { Box, Grid, styled } from "@mui/material";

const SupportBox = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "auto",
  marginTop:'7rem',
  [theme.breakpoints.down("md")]: {
    marginLeft: "7rem",
  },
}));

const AboutBox = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "auto",
  marginTop:'7rem',
  [theme.breakpoints.down("sm")]: {
    marginLeft: "7rem",
  },
}));

const SubscribeBox = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "auto",
  marginTop:'7rem',
  [theme.breakpoints.down("sm")]: {
    marginLeft: "7rem",
  },
}));

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: CommonFunctions.getTheme(),
      isLoading: false,
    };
  }

  onClickSubscibe() {
    var email = document.getElementById("footer-email-field").value;
    if (CommonFunctions.checkValidEmail(email)) {
      this.setState((currentState) => {
        return {
          isLoading: true,
        };
      });
      Apiresponse.subscribe(email).then((res) => {
        if (res === true) {
          window.alert(res.data.message);
        } else if (res === false) {
          window.alert("Something went wrong");
        } else {
          window.alert(res.data.message);
        }
      });
    } else {
      window.alert("Not a valid email address");
    }
    this.setState((currentState) => {
      return {
        isLoading: false,
      };
    });
  }

  render() {
    return (
      <div className="footer footer-theme">
        <div className="footer-inside">
          <div className="footer-content">
            <div className="footer-links">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <div
                    className="footer-logo-location footer-link"
                    style={{ display: "flex", justifyContent: "flex-start", flexDirection:'column', marginLeft:'7rem' }}
                  >
                    <div>
                      <a href={rm.instance.urls.home} style={{ marginTop: 0, display: "flex", alignItems: "center", flexDirection:'row' }}>
                        <img src={cornarFooterLogo} alt="Logo" />
                        <p>ornars</p>
                      </a>
                      <div>
                        <div className="footer-location  footer-theme">
                          <a
                            href="https://goo.gl/maps/nCGNpb3uS3Fu1V1x9"
                            target="_blank"
                            className="footer-theme hover"
                          >
                            43/1/45 <br />
                            Bommanahalli, <br></br> Banglore,<br></br>560068, India{" "}
                          </a>
                          <br></br><br></br>
                          <a
                            href="https://goo.gl/maps/C27YoxA6YR8Aq2zH7"
                            target="_blank"
                            className="footer-theme hover"
                          >
                            {/* CISAPP Online Inc, <br/> */}
                            1334 Brittmoore RD Houstone,<br></br>TX 77043,<br></br> USA<br></br>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <AboutBox>
                    <div className="footer-about footer-link">
                      <h5>About</h5>
                      <div className="footer-links-inside">
                        <a
                          href={rm.instance.urls.blogs}
                          className="footer-theme hover"
                        >
                          Blog
                        </a>
                        <a
                          href={rm.instance.urls.aboutUs}
                          className="footer-theme hover"
                        >
                          About us
                        </a>
                        <a
                          href={rm.instance.urls.terms}
                          className="footer-theme hover"
                        >
                          Terms Of Use
                        </a>
                        <a
                          href={rm.instance.urls.privacy}
                          className="footer-theme hover"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </AboutBox>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <SupportBox>
                    <div className="footer-more footer-link">
                      <h5>Support</h5>
                      <div className="footer-links-inside">
                        <a
                          href={rm.instance.urls.contact}
                          className="footer-theme hover"
                        >
                          Contact us
                        </a>
                      </div>
                    </div>
                  </SupportBox>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SubscribeBox>
                    <div className="footer-subscribe footer-link">
                      <h5>Subscribe to Our Updates</h5>
                      <div className="footer-links-subscribe">
                        <div className="footer-input">
                          <input
                            type="text"
                            placeholder="Enter Your Email"
                            id="footer-email-field"
                            className="footer-email-field"
                          />
                          <button onClick={this.onClickSubscibe.bind(this)}>
                            {this.state.isLoading ? (
                              <img
                                className="footer-spinner"
                                alt="loading"
                                src={Spinner}
                              ></img>
                            ) : (
                              <img src={Send} alt="Send Button" />
                            )}
                          </button>
                        </div>
                        <div className="footer-socialmedia-links">
                          <a
                            href="https://www.instagram.com/cisapp/"
                            target="_blank"
                            className="footer-theme"
                          >
                            <img src={Instagram} alt="Instagram" />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/cisapp/"
                            target="_blank"
                            className="footer-theme"
                          >
                            <img src={LinkidIn} alt="LinkidIn" />
                          </a>
                          <a
                            href="https://twitter.com/connectinsingle"
                            target="_blank"
                            className="footer-theme"
                          >
                            <img src={Twitter} alt="Twitter" />
                          </a>
                          <a
                            href="https://www.facebook.com/connectinsingle"
                            target="_blank"
                            className="footer-theme"
                          >
                            <img src={Facebook} alt="Facebook" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </SubscribeBox>
                </Grid>
              </Grid>
              <Grid container marginTop={{lg:'-5rem',md:'-5rem'}}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    className="footer-playStoreButton"
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <a href="https://play.google.com/store/apps/details?id=com.cisapp.co">
                      <img
                        src={AppStoreBadge}
                        alt="Google play Store Button"
                        className="gettingStartedgooglePlayStoreButton"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.cisapp.co">
                      <img
                        src={GooglePlayStore}
                        alt="Google play Store Button"
                        className="gettingStartedgooglePlayStoreButton"
                      />
                    </a>
                  </div>
                </Grid>
              </Grid>
              <div className="footer-border"></div>
              <div className="footer-copy-rights">
                <img src={CopyRights} alt="CopyRights" />
                <p className="text-theme" style={{ color: "black" }}>
                  {" "}
                  <a
                    href="/terms"
                    className="text-theme"
                    style={{ color: "black" }}
                  >
                    Copyright CiSApp 2021
                  </a>{" "}
                  {/* |{" "}
                  <a
                    href="/privacy_policy.html"
                    // to="/privacy_policy.html" 
                    target="_blank"
                    className="text-theme"
                    style={{ color: "black" }}
                  >
                    Privacy Policy
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;