import React from 'react';
import './Aboutus.scss';
import TopSection from './TopComponents/TopSection';
import BottomSection from './TopComponents/BottomSection';
import pic from './images/pic.png'
import feedPostImg from './images/feedPostImg.png'
import publicExplore from './images/publicExplore.png'
import messengerChatImg from './images/messengerChatImg.png'
import config from '@config/config.json';
import LandingPage from '@components/landingPageCommon/landingPageCommon';
import TopBottomSection from './BottomComponents/TopBottomSection';
import ClientSection from './BottomComponents/ClientSection';
import ReviewSection from './BottomComponents/ReviewSection';

function Aboutus(props) {

  let fontsize;
  if (window.innerWidth < 600) {
    fontsize = window.innerWidth * 0.165333333 + '%'
  }
  else {
    fontsize = window.innerWidth * 0.042534722 + '%'
  }


  return (
    <div className="aboutUs">
      <LandingPage text={config.aboutUs.segment_1} image={publicExplore} imageLeft={messengerChatImg} imageRight={feedPostImg} type="aboutUs">
      </LandingPage>

      <TopSection bgColor={'#FFB90066'} image={pic} ></TopSection>
      <BottomSection bgColor={'#F8281C66'} image={feedPostImg} ></BottomSection>
      <TopSection bgColor={'#FF840066'} image={publicExplore} ></TopSection>
      <BottomSection bgColor={'#5CA40166'} image={feedPostImg} ></BottomSection>
      <TopBottomSection></TopBottomSection>
      <ClientSection></ClientSection>
      <ReviewSection></ReviewSection>
    </div>
  );
}

export default Aboutus;