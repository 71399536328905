import React, { Component } from 'react';
import LandingPage from '@components/landingPageCommon/landingPageCommon';
import rewardsScreenTwo from '@assets/rewardsScreenTwo.png';
import addingFriend from '@assets/addingFriend.png';
import exploreScreen from '@assets/exploreScreen.png';
import Heading from '@components/headings/heading';
import './gettingStarted.scss';
import config from '@config/config.json';
import GooglePlayBadge from '@assets/google-play-badge.png';

class GettingStarted extends Component {

    render() {
        return (
            <div className="gettingStarted">
                <LandingPage text={config.gettingStarted.segment_1} image={addingFriend} imageLeft={rewardsScreenTwo} imageRight={exploreScreen}/>
                <div className="gettingStarted-white background-blue">
                <canvas className="canvas"></canvas>
                    <div className="text-theme gettingStarted-white-inside">
                        <p className="gettingStarted-white-description">{config.gettingStarted.segment_2.description}</p>
                        <div className="gettingStarted-app-buttons">
                            <a href="https://play.google.com/store/apps/details?id=com.cisapp.co"><img src={GooglePlayBadge} alt="Google play Store Button" className="gettingStartedgooglePlayStoreButton" /></a>
                            {/* <a><img src={GooglePlayBadge} alt="Google play Store Button" className="gettingStartedAppStoreButton" /></a> */}
                        </div>
                        <div className="gettingStarted-white-points">
                            <Heading className="text-theme" type="Point" text={config.gettingStarted.segment_2.points} ></Heading>
                        </div>
                    </div>
                </div>
                <div className="background-blue gettingStarted-blue" >
                    <canvas className="canvas"></canvas>
                    <div className="common-p gettingStarted-blue-inside">
                        <h1 className="common-h1">{config.gettingStarted.segment_3.heading}</h1>
                        <div className="gettingStarted-blue-points">
                            <Heading className="common-p" type="Point" text={config.gettingStarted.segment_3.points} ></Heading>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GettingStarted;