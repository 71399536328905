import { Box, Container, Grid, Paper, Typography, styled } from '@mui/material'
import React from 'react'
import Image1 from '../images/Image1.png'


const Item = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  height: '300px',
  width: '100%',
  display: 'flex'
}));

const Circle = styled(Box)(({ theme }) => ({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  backgroundColor:'#F29F05',
  marginBottom:'30px'
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '30px',
  letterSpacing: '0em',
  textAlign: 'left',
}))

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#787878'
}))

const PaperItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  height: 60,
  lineHeight: '60px',
  width:'225px',
  height:'300px',
  display:'flex',
  flexDirection:'column',
  padding:'15px',
  borderRadius:'10px'
}));

const PaperHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
}));

const PaperText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
  color:'#787878'
}));

const TopBottomSection = () => {
  return (
    <Container maxWidth="lg" style={{marginBottom:'150px'}}>
      <Box sx={{height: '100vh', padding: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={5}>
            <Item>
              <img alt='' src={Image1} />
            </Item>
          </Grid>
          <Grid item xs={6} md={7}>
            <Item flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
              <Heading>Our Mission Is To Save <br /> Your Time</Heading>
              <br /><br /><br />
              <Description>Viverra vitae congue eu consequat ac felis. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamco rper. Velit ut tortor pretium viverra suspendisse potentinullam ac tortor.</Description>
              <br /><br /><br />
              <Description>Eget egestas purus viverra accumsan in nisl nisi scelerisque. Tincidunt augue interdum velit euismod in pellentesque.</Description>
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6} md={3}>
            <PaperItem elevation={24}>
              <Circle></Circle>
              <PaperHeading>Save Your <br/> Time</PaperHeading>
              <PaperText>Vulputate dignissim suspendisse in est ante in nibh mauris. Pretium nibh ipsum consequat nisl vel pretium lectus quam id.</PaperText>
            </PaperItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <PaperItem elevation={24}>
              <Circle></Circle>
              <PaperHeading>Regular <br/> Discounts</PaperHeading>
              <PaperText>Vulputate dignissim suspendisse in est ante in nibh mauris. Pretium nibh ipsum consequat nisl vel pretium lectus quam id.</PaperText>
            </PaperItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <PaperItem elevation={24}>
            <Circle></Circle>
              <PaperHeading>Variety <br/> Food</PaperHeading>
              <PaperText>Vulputate dignissim suspendisse in est ante in nibh mauris. Pretium nibh ipsum consequat nisl vel pretium lectus quam id.</PaperText>
            </PaperItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <PaperItem elevation={24}>
            <Circle></Circle>
              <PaperHeading>Save Your <br/> Time</PaperHeading>
              <PaperText>Vulputate dignissim suspendisse in est ante in nibh mauris. Pretium nibh ipsum consequat nisl vel pretium lectus quam id.</PaperText>
            </PaperItem>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default TopBottomSection