import goCornarLogo from "@assets/goCornar.png";
import React, { Component } from "react";
import "./navbar.scss";
import CommonFunctions from "@functions/commonFunctions";
import rm from "@rm";
import { Typography, styled } from "@mui/material";


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: CommonFunctions.getTheme(),
      weight: 1,
    };
    switch (window.location.pathname) {
      case rm.instance.urls.home:
        this.state.weight = 1;
        break;
      case rm.instance.urls.aboutUs:
        this.state.weight = 2;
        break;
    }
  }



  render() {
    return (
      <>
        <div className="navbar">
          <div className="navbar-inside">
            <div className="navbar-logo">
              <a href={rm.instance.urls.home}>
                <div>
                  <img
                    className="navbar-logo-img"
                    src={goCornarLogo}
                    alt="logo"
                  ></img>
                </div>
                <p>GoCornar</p>
              </a>
            </div>

            <div className="navbar-links">
              <a href={"https://cornars.com/home"} style={{ fontWeight: 600 }}>
                {/* {this.state.weight == 1 ? <b>Home</b> : "Cornars"} */}Home
              </a>
              {/* <a href={rm.instance.urls.aboutUs} style={{ fontWeight: 900 }}>
                {this.state.weight == 2 ? <b>About us</b> : "About us"}
              </a> */}
              {/* <a
                href={rm.instance.urls.forCreators}
                style={{ fontWeight: 900 }}
              >
                {this.state.weight == 3 ? <b>Creators</b> : "Creators"}
              </a>
              <a
                href={rm.instance.urls.forInvestors}
                style={{ fontWeight: 900 }}
              >
                {this.state.weight == 4 ? <b>Investors</b> : "Investors"}
              </a> */}
            </div>
            {/* <div className="navbar-theme">
              <img
                className="navbar-theme-icon"
                src={Theme}
                alt="theme"
                onClick={() => this.onClickChangeTheme()}
                id="navbar-theme-icon"
              ></img>
            </div> */}
          </div>
        </div>
        {/* <SuperApp>The Super App</SuperApp> */}
      </>
    );
  }
}

export default Navbar;
