import React, { Component } from 'react';
import './messaging.scss';
import Normalchat from '../images/normalChat.png';
import ScheduledImg from '../images/scheduledImg.png';
import PrivateChatImg from '../images/privateChatImg.png';
import ScheduledchatImg from '../images/scheduledchatImg.png';
import PrivateImg from '../images/privateImg.png';
import ChatImg from '@assets/chatImg.png';

class Messaging extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.text = this.props.text;
        this.changeClassName = this.changeClassName.bind(this);   
        this.messagingAnimation = "messaging-animate";
    }
    componentDidMount() {
        this.component = document.getElementById(this.messagingAnimation);
        window.addEventListener("scroll", this.changeClassName);
    }

    changeClassName() {
        if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
            var screen = document.getElementById(this.messagingAnimation + " 5");
            screen.className = screen.className.replace("hide","") + " screen-animate-messaging";
            var screen = document.getElementById(this.messagingAnimation + " 6");
            screen.className = screen.className.replace("hide","") + " screen-animate-messaging";
            var screen = document.getElementById(this.messagingAnimation + " 7");
            screen.className = screen.className.replace("hide","") + " screen-animate-messaging";

            var screen = document.getElementById(this.messagingAnimation + " messagingIcon1");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingIcon2");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingIcon3");
            screen.className = screen.className.replace("hide","") + " text-anime";

            var screen = document.getElementById(this.messagingAnimation + " messagingHeading1");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingHeading2");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingHeading3");
            screen.className = screen.className.replace("hide","") + " text-anime";

            var screen = document.getElementById(this.messagingAnimation + " messagingDescription1");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingDescription2");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.messagingAnimation + " messagingDescription3");
            screen.className = screen.className.replace("hide","") + " text-anime";


            window.removeEventListener("scroll", this.changeClassName);
        }
    }
    render() { 
        return ( 
            <div className="messaging background-blue" id = {this.messagingAnimation}>
            <canvas className="canvas"></canvas>
                <div className="messaging-inside">
                    <h1 className="common-h1 ">{this.text.heading}</h1>
                    <h4 className="common-p">{this.text.sub_heading}</h4>
                    <div className="messaging-container">
                        <div className="messaging-content">
                            <img src={PrivateChatImg} alt="" className="screen hide" id = {this.messagingAnimation + " 5"} /><br/>
                            <img src={PrivateImg} alt="" className="icon " id ={this.messagingAnimation + " messagingIcon1"} />
                            <h3 className="common-p " id ={this.messagingAnimation + " messagingHeading1"}>{this.text.modes[0].heading}</h3>
                            <p className="common-p" id = {this.messagingAnimation + " messagingDescription1"}>{this.text.modes[0].description}</p>
                        </div>
                        <div className="messaging-content">
                            <img src={ScheduledchatImg} alt="" className="screen hide"  id = {this.messagingAnimation + " 6"}/><br/>
                            <img src={ScheduledImg} alt="" className="icon"  id ={this.messagingAnimation + " messagingIcon2"}/>
                            <h3 className="common-p"  id ={this.messagingAnimation + " messagingHeading2"}>{this.text.modes[1].heading}</h3>
                            <p className="common-p" id = {this.messagingAnimation + " messagingDescription2"}>{this.text.modes[1].description}</p>
                        </div>
                        <div className="messaging-content">
                            <img src={ChatImg} alt="" className="screen hide"  id = {this.messagingAnimation + " 7"} /><br/>
                            <img src={Normalchat} alt="" className="icon" id ={this.messagingAnimation + " messagingIcon3"} />
                            <h3 className="common-p"  id ={this.messagingAnimation + " messagingHeading3"}>{this.text.modes[2].heading}</h3>
                            <p className="common-p" id = {this.messagingAnimation + " messagingDescription3"}>{this.text.modes[2].description}</p>
                        </div>
                    </div>
                </div>
            </div>
            
         );

         
    }
}
 
export default Messaging;