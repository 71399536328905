import React, { Component } from 'react';
import './userBenefits.scss';
import benefits from '@assets/benefits.png';
import Heading from '@components/headings/heading';
import rm from '@rm';

class UserBenefits extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.text = this.props.text;
    }
    render() {
        return ( 
            <div className="userBenefits background-blue">
                <canvas className="canvas"></canvas>
                <div className="userBenefits-inside">

                    <div class="userBenifits-subHeading">
                    
                    <Heading className="common-p userBenefits-subheading1" type="SHDisc" color="#1F89CA" text={this.text.sub_headings[0]}></Heading> 
                    
                    <Heading className="common-p userBenefits-subheading1" type="SHDisc" color="#D878FF" text={this.text.sub_headings[1]}></Heading> 
                    
                    <Heading className="common-p userBenefits-subheading1" type="SHDisc" color="#FFA621" text={this.text.sub_headings[2]}></Heading> 
                    
                        <Heading className="common-p userBenefits-subheading1" type="SHDisc" color="#8B6CD8" text={this.text.sub_headings[3]}></Heading>
                        <center><a href={rm.instance.urls.gettingStarted}><button>Get Started</button> </a></center>
                    </div>
                </div>
            </div>
        
         );
    }
}
 
export default UserBenefits;